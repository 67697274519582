<template>
  <div class="fixed left-0 top-0 z-10 m-2">
    <Button variant="link" @click="app.leftNavPinned = true">
      <IconMenu class="h-5 w-5" />
    </Button>
  </div>

  <div
    id="left-nav"
    ref="el"
    class="fixed inset-0 z-20 hidden w-full max-w-[18em] flex-col bg-dark lg:relative lg:flex lg:border-r lg:border-white/10"
    :class="{
      '!flex !border-r !shadow': app.leftNavPinned,
    }"
    :style="{
      background: `var(--bg1)`,
    }"
  >
    <div class="w-full p-4">
      <UserNav class="w-full" />
    </div>

    <ul class="p-4">
      <li
        class="whitespace-nowrap"
        :class="{ 'force-not-active': onAlertBoxRoute }"
      >
        <NuxtLink
          to="/widgets"
          @click="$track('left_nav_clicked', { nav_item_name: 'widgets' })"
        >
          <IconWidgets class="h-6 w-6 p-0.5" />
          <span>{{ $t("pages.title.widgets") }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          to="/library"
          @click="$track('left_nav_clicked', { nav_item_name: 'library' })"
        >
          <IconOverlays class="h-6 w-6 p-0.5" />
          <span>{{ $t("Overlays & Themes") }}</span>
        </NuxtLink>
      </li>

      <li>
        <NuxtLink
          to="/apps"
          @click="$track('left_nav_clicked', { nav_item_name: 'app_store' })"
        >
          <!-- <IconStore class="h-6 w-6 p-0.5" /> -->
          <Icon size="1.5em" name="bxs:window-alt" />
          <span>{{ $t("Apps") }}</span>
        </NuxtLink>
      </li>

      <li>
        <NuxtLink
          to="/stream-labels"
          @click="
            $track('left_nav_clicked', { nav_item_name: 'stream-labels' })
          "
        >
          <IconStreamLabels class="h-6 w-6 p-0.5" />
          <span>{{ $t("pages.title.stream-labels") }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          to="/cloudbot"
          @click="$track('left_nav_clicked', { nav_item_name: 'cloudbot' })"
        >
          <IconCloudbot class="h-6 w-6 p-0.5" />
          <span>{{ $t("pages.title.cloudbot") }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          to="/tipping"
          @click="$track('left_nav_clicked', { nav_item_name: 'tipping' })"
        >
          <IconTipping class="h-6 w-6 p-0.5" />
          <span>{{ $t("pages.title.tipping") }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          to="/multistream"
          @click="$track('left_nav_clicked', { nav_item_name: 'multistream' })"
        >
          <IconMultistream class="h-6 w-6 p-0.5" />
          <span>{{ $t("pages.title.multistream") }}</span>
        </NuxtLink>
      </li>
    </ul>

    <!-- <LeftNavApps /> -->

    <div class="flex-grow" />

    <LeftNavBottom />
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";

const app = useAppStore();
const { track: $track } = useTrackingStore();
const router = useRouter();

const el = ref(null);
const route = useRoute();
const onAlertBoxRoute = computed(() => route.path === "/widgets/alert-box");

// unpin the menu when we navigate
router.beforeResolve(() => {
  app.leftNavPinned = false;
});

onClickOutside(el, () => {
  app.leftNavPinned = false;
});
</script>

<style>
#left-nav {
  ul {
    @apply space-y-1;
  }

  li {
    @apply whitespace-nowrap;

    a,
    button {
      -webkit-user-drag: none;

      @apply inline-flex w-full items-center space-x-2 rounded-lg px-4 py-2;

      svg {
        @apply flex-shrink-0 opacity-75 brightness-75;
      }
    }

    &:not(.force-not-active) a.router-link-active,
    &:hover a,
    &:hover button {
      @apply bg-outer-space-950 brightness-150;

      svg {
        @apply opacity-100 brightness-100;
      }
    }
  }
}
</style>

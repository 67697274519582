<template>
  <div class="w-full max-w-xl self-start pt-12">
    <template v-if="!streamLabels.hasImportableStreamLabels">
      <Card>
        <div class="p-4">
          <h3 class="text-lg font-medium">
            Your Stream Labels We're Imported!
          </h3>

          <p class="text-muted-foreground">
            You can safely close your Stream Labels App. The Streamlabs Plugin
            for OBS will keep your Stream Labels up to date!
          </p>
        </div>
      </Card>
    </template>
    <template v-else>
      <Table>
        <TableCaption>
          We've found {{ foundLabel }} in your sources that can be imported.
        </TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Source</TableHead>
            <TableHead class="w-[150px]">Label Type</TableHead>
            <TableHead>Preview</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          <template
            v-for="item in streamLabels.importableStreamLabels"
            :key="item.fileName"
          >
            <TableRow>
              <TableCell>{{ item.source.name }}</TableCell>
              <TableCell class="font-medium">{{ item.label.label }}</TableCell>

              <TableCell>
                <span
                  v-if="item.label.value"
                  class="rounded-sm bg-muted px-2 py-1.5 font-medium tracking-wider"
                >
                  {{ item.label.value }}
                </span>
              </TableCell>
            </TableRow>
          </template>
        </TableBody>
      </Table>

      <Card class="mt-24">
        <div class="flex items-center justify-between space-x-4 p-4">
          <label for="enabled">
            <h3 class="text-lg font-medium">Import {{ foundLabel }}</h3>
            <p class="text-muted-foreground">
              By importing your Stream Labels into the Streamlabs Plugin, you
              will no longer need to run the Stream Labels App!
            </p>
          </label>

          <UiSwitch
            id="enabled"
            v-model:checked="importStreamLabelsEnabled"
            variant="background"
            size="lg"
          />
        </div>
      </Card>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useVModel } from "@vueuse/core";

const props = defineProps<{
  importStreamLabelsEnabled: boolean;
}>();

const emit = defineEmits(["update:importStreamLabelsEnabled"]);

const importStreamLabelsEnabled = useVModel(
  props,
  "importStreamLabelsEnabled",
  emit,
);

const streamLabels = useStreamLabelsStore();

const foundLabel = computed(() => {
  if (streamLabels.importableStreamLabels.length === 1) {
    return `${streamLabels.importableStreamLabels.length} Stream Label`;
  }

  return `${streamLabels.importableStreamLabels.length} Stream Labels`;
});
</script>

<template>
  <svg viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 0.99997C0 0.447685 0.447715 -3.05176e-05 1 -3.05176e-05H13C13.5523 -3.05176e-05 14 0.447685 14 0.99997C14 1.55225 13.5523 1.99997 13 1.99997H1C0.447716 1.99997 0 1.55225 0 0.99997ZM0 11C0 10.4477 0.447715 9.99997 1 9.99997H13C13.5523 9.99997 14 10.4477 14 11C14 11.5523 13.5523 12 13 12H1C0.447716 12 0 11.5523 0 11ZM1 4.99997C0.447715 4.99997 0 5.44768 0 5.99997C0 6.55225 0.447716 6.99997 1 6.99997H13C13.5523 6.99997 14 6.55225 14 5.99997C14 5.44768 13.5523 4.99997 13 4.99997H1Z"
      fill="currentColor"
    />
  </svg>
</template>

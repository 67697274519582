<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M1.925 4.9 4.9 1.925 3.237.263a.846.846 0 0 0-1.224 0l-1.75 1.75a.846.846 0 0 0 0 1.225L1.924 4.9Zm10.15 4.2L9.1 12.075l1.663 1.663c.175.175.35.262.612.262H14v-2.625a.794.794 0 0 0-.262-.612L12.075 9.1ZM9.012.263a.846.846 0 0 1 1.226 0l3.5 3.5c.35.35.35.875 0 1.225l-8.75 8.75a.846.846 0 0 1-1.226 0l-3.5-3.5a.846.846 0 0 1 0-1.225L2.4 6.875l1.488 1.488 1.028-1.041-1.488-1.488 2.4-2.4 1.488 1.488 1.047-1.034L6.875 2.4 9.012.263Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

import { z } from "zod";

export function toZodEnum(keys: string[]) {
  const [firstKey, ...otherKeys] = keys;
  return z.enum([firstKey, ...otherKeys]);
}

export function getDefaultValue<T>(zodSchema: z.ZodType<T>): T | undefined {
  if (zodSchema instanceof z.ZodDefault) {
    return zodSchema._def.defaultValue();
  }
  return undefined;
}

export function getInitialValues(fieldsets: Fieldset[]) {
  const defaults: {
    [x: string]: any;
  } = {};

  for (const fieldset of fieldsets || []) {
    for (const field of fieldset.fields) {
      if (field.type === "checkbox_group") {
        for (const subfield of field.fields) {
          defaults[subfield.name] = getDefaultValue(unref(subfield.schema));
        }
        continue;
      }

      defaults[field.name] = getDefaultValue(unref(field.schema));
    }
  }

  return defaults;
}

<template>
  <!-- <Icon size="1.25em" name="fa-solid:tag" /> -->
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 12.9999V6.99994H7V-6.10352e-05H1C0.4 -6.10352e-05 0 0.399939 0 0.999939V12.9999C0 13.5999 0.4 13.9999 1 13.9999H13C13.6 13.9999 14 13.5999 14 12.9999ZM14 4.99994H9V-6.10352e-05L14 4.99994Z"
      fill="currentColor"
    />
  </svg>
</template>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M8 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm6 6a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

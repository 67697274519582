<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M7 12.5c-3.85 0-7-2.713-7-6.125C0 2.962 3.15.25 7 .25s7 2.712 7 6.125c0 1.575-.7 2.975-1.75 4.025v2.333a1 1 0 0 1-1.394.92l-2.893-1.24C7.7 12.5 7.35 12.5 7 12.5ZM7 3c1.262 0 2.286 1.074 2.286 2.4.947 0 1.714.806 1.714 1.8S10.232 9 9.286 9H4.714v-.021C3.745 8.833 3 7.958 3 6.9c0-1.083.78-1.974 1.783-2.088C5.033 3.771 5.931 3 7 3Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<template>
  <DialogOnboarding
    :open="!app.onboardingComplete"
    @cancel="app.onboardingComplete = true"
  />

  <DialogUltraRequired
    v-if="dialogs.dialogs.ULTRA_REQUIRED"
    :open="true"
    v-bind="dialogs.dialogs.ULTRA_REQUIRED"
    @cancel="dialogs.dialogs.ULTRA_REQUIRED = false"
  />

  <DialogRestartRequired
    :open="dialogs.dialogs.RESTART_OBS"
    @cancel="dialogs.dialogs.RESTART_OBS = false"
  />

  <DialogLogin :open="!auth.isAuthenticated" />

  <DialogBetaRestricted :open="dialogs.dialogs.BETA_RESTRICTED" />

  <DialogGoLive
    v-if="dialogs.dialogs.GO_LIVE"
    :open="true"
    @cancel="dialogs.dialogs.GO_LIVE = false"
  />

  <DialogTikTokApply
    :open="dialogs.dialogs.TIKTOK_APPLY"
    @close="dialogs.dialogs.TIKTOK_APPLY = false"
  />

  <DialogPluginOutdated
    v-if="dialogs.dialogs.PLUGIN_OUTDATED"
    :open="true"
    v-bind="dialogs.dialogs.PLUGIN_OUTDATED"
  />

  <DialogFeedback
    :open="dialogs.dialogs.FEEDBACK"
    @close="dialogs.dialogs.FEEDBACK = false"
  />

  <template v-for="(item, i) in dialogs.commonDialogs" :key="i">
    <UiAlertDialog :open="true">
      <UiAlertDialogContent>
        <UiAlertDialogHeader>
          <UiAlertDialogTitle>{{ item.title }}</UiAlertDialogTitle>
          <UiAlertDialogDescription
            v-sanitize-html="mdToHtml(item.description)"
            class="whitespace-pre-wrap pb-4"
          >
          </UiAlertDialogDescription>
        </UiAlertDialogHeader>
        <UiAlertDialogFooter>
          <UiAlertDialogCancel
            :disabled="item.busy"
            @click="() => item.cancelBtnFn()"
          >
            {{ item.cancelBtnText }}
          </UiAlertDialogCancel>
          <UiAlertDialogAction as-child @click="() => item.confirmBtnFn()">
            <Button :busy="item.busy">{{ item.confirmBtnText }}</Button>
          </UiAlertDialogAction>
        </UiAlertDialogFooter>
      </UiAlertDialogContent>
    </UiAlertDialog>
  </template>

  <template v-for="(item, i) in dialogs.genericDialogs" :key="i">
    <UiAlertDialog :open="true">
      <UiAlertDialogContent>
        <UiAlertDialogHeader v-if="item.title || item.description">
          <UiAlertDialogTitle v-if="item.title">
            {{ item.title }}
          </UiAlertDialogTitle>
          <UiAlertDialogDescription
            v-if="item.description"
            v-sanitize-html="mdToHtml(item.description)"
            class="whitespace-pre-wrap pb-4"
          >
          </UiAlertDialogDescription>
        </UiAlertDialogHeader>

        <div v-if="item.html" v-sanitize-html="mdToHtml(item.html)" />

        <UiAlertDialogFooter v-if="item.buttons">
          <template v-for="(button, i) in item.buttons" :key="i">
            <UiAlertDialogCancel
              v-if="button.type === 'cancel'"
              :disabled="item.isBusy"
              @click="
                () =>
                  button.onClick({
                    closeDialog: item.close,
                    setDialogBusy: item.setBusy,
                  })
              "
            >
              {{ button.text }}
            </UiAlertDialogCancel>

            <UiAlertDialogAction
              v-else-if="button.type === 'action'"
              as-child
              @click="
                () =>
                  button.onClick({
                    closeDialog: item.close,
                    setDialogBusy: item.setBusy,
                  })
              "
            >
              <Button :busy="item.isBusy">{{ button.text }}</Button>
            </UiAlertDialogAction>

            <Button
              v-else
              :busy="item.isBusy"
              :variant="button.variant"
              :class="button.classes"
              @click="
                () =>
                  button.onClick({
                    closeDialog: item.close,
                    setDialogBusy: item.setBusy,
                  })
              "
            >
              {{ button.text }}
            </Button>
          </template>
        </UiAlertDialogFooter>
      </UiAlertDialogContent>
    </UiAlertDialog>
  </template>
</template>

<script setup lang="ts">
import { parse as mdToHtml } from "marked";

const auth = useAuthStore();
const app = useAppStore();
const dialogs = useDialogsStore();
</script>

<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
  TagsInputRoot,
  type TagsInputRootEmits,
  type TagsInputRootProps,
  useForwardPropsEmits,
} from "radix-vue";
import { cn } from "@/lib/utils";

const props = defineProps<
  TagsInputRootProps & { class?: HTMLAttributes["class"] }
>();
const emits = defineEmits<TagsInputRootEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <TagsInputRoot
    v-bind="forwarded"
    :class="
      cn(
        'flex flex-wrap items-center gap-2 rounded-md border border-input bg-background px-3 py-2 text-base',
        'rounded-md bg-secondary',
        // 'relative w-full rounded-md bg-secondary px-3 py-2 text-left text-base leading-6 text-white shadow-sm outline-none hover:brightness-110',
        props.class,
      )
    "
  >
    <slot />
  </TagsInputRoot>
</template>

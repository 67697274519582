<template>
  <UiDialog
    @update:open="
      (is) => {
        if (!is) {
          $emit('close');
        }
      }
    "
  >
    <UiDialogContent>
      <UiDialogHeader>
        <UiDialogTitle> TikTok: Apply for Streaming Access </UiDialogTitle>
        <UiDialogDescription class="space-y-4 pb-4">
          <p>
            In order to stream on TikTok, you must apply for streaming access.
            Once you apply, you'll have to check back here to see if you're
            approved.
          </p>
          <p>
            Alternatively, you can check the
            <a
              class="underline underline-offset-4 brightness-125 hover:brightness-150"
              :href="tiktokProducerUrl"
              @click.stop.prevent="openBrowserTo(tiktokProducerUrl)"
              >TikTok LIVE Center</a
            >
            for your Server URL and Key and create a custom destination in the
            previous step.
          </p>
        </UiDialogDescription>
      </UiDialogHeader>

      <div class="my-1" />

      <UiDialogFooter>
        <Button
          @click="doCheckStatus"
          class="mr-auto"
          variant="neutral"
          :disabled="!applyUrl"
          :busy="!applyUrl"
        >
          Check Status
        </Button>

        <Button @click="$emit('close')" variant="ghost">Close</Button>

        <Button :disabled="!applyUrl" :busy="!applyUrl" @click="doApply">
          Apply for Access
        </Button>
      </UiDialogFooter>
    </UiDialogContent>
  </UiDialog>
</template>

<script setup lang="ts">
import { openBrowserTo } from "~/lib/bindings";

const emit = defineEmits(["close"]);

const tiktokProducerUrl = "https://livecenter.tiktok.com/producer";

const applyUrl = computed(() => api.golive.tiktok.apply.data?.url);

const api = useApiStore();

const doApply = async () => {
  //   const { url } = await api.golive.tiktok.apply.ensure();
  if (!applyUrl.value) return;

  openBrowserTo(applyUrl.value);
};

const doCheckStatus = doApply;
</script>

<template>
  <UiDialog @update:open="onUpdateOpen">
    <UiDialogContent :class="currentStep.containerClass">
      <UiDialogHeader>
        <UiDialogTitle>
          {{ currentStep?.title }}
        </UiDialogTitle>
        <UiDialogDescription>
          {{ currentStep?.description }}
        </UiDialogDescription>
      </UiDialogHeader>

      <div
        class="flex items-center justify-center"
        :class="{
          'pointer-events-none opacity-50': busy,
        }"
      >
        <DialogGoLiveDestinationsStep
          :class="{ hidden: currentStepId !== Step.Destinations }"
        />

        <Form
          v-if="fieldsets"
          :fieldsets="fieldsets"
          :class="{
            hidden: currentStepId !== Step.Settings,
          }"
        />
      </div>

      <DialogFooter>
        <Button
          v-if="canGoPrev"
          variant="neutral"
          class="sm:mr-auto"
          :disabled="busy"
          @click="prevStep"
        >
          {{ $t("Back") }}
        </Button>

        <Button v-if="isNotLastStep" :busy="busy" @click="nextStep">
          {{ $t("Next") }}
        </Button>

        <Button v-else :busy="busy" @click="startStreaming">
          {{ $t("Start Streaming") }}
        </Button>
      </DialogFooter>
    </UiDialogContent>
  </UiDialog>
</template>

<script setup lang="ts">
import { generateSignedUrl, updateRstUserProfile } from "~/lib/streamlabs-api";
import Bindings, { openBrowserTo, setStreamSettings } from "~/lib/bindings";
import { decrypt } from "~/lib/security";
import { useToast } from "~/components/ui/toast";
import UiButton from "~/components/ui/button/Button.vue";
import { RstToPlatformType } from "~/stores/multistream.store";
import { sleep } from "~/lib/util";
import { getInitialValues } from "~/lib/forms";

const { t: $t } = useI18n();
const multistream = useMultistreamStore();
const app = useAppStore();
const dialogs = useDialogsStore();
const auth = useAuthStore();
const api = useApiStore();
const obs = useObsStore();
const goLive = useGoLiveStore();
const { toast } = useToast();
const { track: $track } = useTrackingStore();

const ytInfo = api.golive.youtube.info;
const rstUserProfile = api.multistream.user.profile;

enum Step {
  Destinations,
  Settings,
}

const busy = ref(false);

const emit = defineEmits(["cancel"]);

// refresh you tube info on enable
watch(
  () => multistream.isYouTubeEnabled,
  (is) => is && ytInfo.refresh(),
);

const { fieldsets, form } = useGoLiveForm();

const steps: Record<
  Step,
  {
    title?: string;
    description?: string;
    containerClass?: string;
    validate?: Function;
  }
> = {
  [Step.Destinations]: {
    title: $t("Choose Destinations"),
    description: $t(`Choose one or more destinations below`),
    containerClass: "max-w-xl",
    async validate() {
      const validated = await form.validateField("destinationCount");
      if (!validated.valid) {
        throw new Error("!");
      }
    },
  },

  [Step.Settings]: {
    title: $t("Platform Settings"),
    description: $t(`Customize your stream settings for each platform`),
    containerClass: "max-w-xl",
    async validate() {
      const validated = await form.validate();
      if (!validated.valid) {
        throw new Error("!");
      }
    },
  },
};

const nextStep = async () => {
  if (currentStep.value.validate) {
    try {
      await currentStep.value.validate();
    } catch (err) {
      console.error(err);
      return;
    }
  }

  currentStepIdx.value += 1;
};

const prevStep = () => {
  currentStepIdx.value -= 1;
};

const canGoPrev = computed(() => currentStepIdx.value > 0);
const isNotLastStep = computed(() => {
  return (
    currentStepIdx.value < stepOrder.value.length - 1 &&
    (fieldsets.value?.length || !multistream.enabledDestinations.length)
  );
});

const getPlatformSettingsPayload = (
  values: {
    [x: string]: any;
  },
  platform: RstToPlatformType,
) => {
  switch (platform) {
    case "twitch":
      return {
        title: values.twitch_title,
        category: values.twitch_category,
        tags: values.twitch_tags.length
          ? values.twitch_tags.join(",")
          : undefined,
      };

    case "tiktok":
      return {
        title: values.tiktok_title,
        category: values.tiktok_category,
      };

    case "youtube":
      return {
        title: values.youtube_title,
        privacy: values.youtube_privacy,
        category: values.youtube_category,
        description: values.youtube_description,
        auto_start: values.youtube_auto_start,
        auto_stop: values.youtube_auto_stop,
        dvr: values.youtube_dvr,
        for_kids: values.youtube_for_kids,
        latency: values.youtube_latency,
        projection: values.youtube_360_video ? "360" : "rectangular",
        broadcast_id: values.youtube_broadcast_id,
        thumbnail: values.youtube_thumbnail,
      };
    case "facebook":
      return {
        title: values.facebook_title,
        description: values.facebook_description,
      };
    case "twitter":
      return {
        title: values.twitter_title,
      };
    case "trovo":
      return {
        title: values.trovo_title,
      };
  }

  return {};
};

const startStreaming = async () => {
  await form.handleSubmit(async (values) => {
    $track("going_live_via_plugin_attempted", {
      type:
        multistream.enabledDestinations.length > 1 ? "multistream" : "basic",
      platforms: multistream.enabledDestinations
        .filter((d) => d.type === "platform")
        .map((d) => d.platform_type),
    });

    busy.value = true;

    if (values.youtube_thumbnail instanceof File) {
      // ... upload to s3 and replace with url
      const { data } = await generateSignedUrl({
        type: "youtube-thumbnail",
        extension: "png",
      });

      const formData = new FormData();

      for (const key in data.value.inputs) {
        formData.append(key, data.value.inputs[key]);
      }

      formData.append("file", values.youtube_thumbnail);

      await useFetch(data.value.attributes.action, {
        method: "POST",
        body: formData,
      });

      values.youtube_thumbnail = {
        path: data.value.path,
      };
    } else {
      // otherwise don't supply it, it's already uploaded
      values.youtube_thumbnail = undefined;
    }

    let chatDockUrl;

    if (multistream.enabledDestinations.length === 1) {
      const [destination] = multistream.enabledDestinations;

      switch (destination.type) {
        case "platform": {
          const payload = getPlatformSettingsPayload(
            values,
            destination.platform_type,
          );

          const response = await goLive.startBroadcast(
            destination.platform_type,
            payload,
          );

          if (
            !response.data.rtmp ||
            !response.data.key ||
            !auth.authResponseParams
          ) {
            console.error(
              `!response.data.value.rtmp || !response.data.value.key || !auth.authResponseParams`,
            );

            const desinationName =
              multistream.platformConfigs[destination.platform_type].label;

            const { dismiss } = toast({
              duration: 60000,
              title: "Failed to Start Stream",
              description: `We were unable to fetch a valid streaming key. If issues persist, please reconnect and try again.`,
              variant: "destructive",
              action: h(
                "div",
                {
                  class: "space-x-2 flex justify-end w-full",
                },
                [
                  h(
                    UiButton,
                    {
                      variant: "link",
                      class: "text-white/70",
                      size: "sm",
                      altText: "Dismiss",
                      onClick: () => {
                        dismiss();
                      },
                    },
                    {
                      default: () => "Dismiss",
                    },
                  ),

                  h(
                    UiButton,
                    {
                      variant: "destructive",
                      class: "!border-white/50 border text-white/90",
                      size: "sm",
                      altText: `Reconnect ${desinationName}`,
                      onClick: () => {
                        multistream.connectPlatform(
                          PlatformTypeToRst[destination.platform_type],
                        );

                        dismiss();
                      },
                    },
                    {
                      default: () => `Reconnect ${desinationName}`,
                    },
                  ),
                ],
              ),
            });

            busy.value = false;
            return;
          }

          const streamKey = await decrypt(
            response.data.key,
            auth.authResponseParams.v,
          );

          await setStreamSettings({
            key: streamKey,
            url: response.data.rtmp,
          });

          if (response.data.chat_url) {
            const url = new URL(
              response.data.chat_url.replace(/https:\/\/?/, "https://"),
            );

            if (url.host.endsWith("twitch.tv")) {
              url.searchParams.set("darkpopout", "");
            }

            chatDockUrl = url.toString();

            if (destination.platform_type === "tiktok") {
              openBrowserTo(chatDockUrl);
              chatDockUrl = `${window.location.origin}/docks/tiktok-chat`;
            }
          }
          break;
        }
        case "relay": {
          const relay = destination;
          await setStreamSettings({
            key: relay.streamKey,
            url: relay.streamUrl,
          });
          break;
        }
      }
    } else {
      if (
        !multistream.canMultistreamWithoutUltra &&
        !auth.ensureUltra("go_live_multiple_destinations", {
          refl: "obsplugin-multistream",
        })
      ) {
        busy.value = false;
        return;
      }

      if (!rstUserProfile.data) {
        console.log(`!rstUserProfile`, rstUserProfile);
        return;
      }

      rstUserProfile.data.title = values.title;
      rstUserProfile.data.description = values.description;

      const multistreamChatPlatforms: MulitstreamChatSupportedPlatform[] = [];

      for (const [k, v] of Object.entries(RstToPlatformType) as Entries<
        typeof RstToPlatformType
      >) {
        const enabled = multistream.enabledDestinations.some(
          (destination) =>
            destination.type === "platform" && destination.platform_type === v,
        );

        rstUserProfile.data[k] = enabled;

        if (enabled) {
          multistreamChatPlatforms.push(v);
          rstUserProfile.data[v] = getPlatformSettingsPayload(values, v);
        } else {
          delete rstUserProfile.data[v];
        }
      }

      const [
        {
          data: {
            value: { streamKey },
          },
        },
        {
          data: { server: streamUrl },
        },
      ] = await Promise.all([
        multistream.enableMultistream(),
        api.get<RstIngestResponse>(`/api/v5/obs-plugin/rst/ingest`),
        updateRstUserProfile(rstUserProfile.data),
      ]);

      await setStreamSettings({
        key: streamKey,
        url: streamUrl,
      });

      rstUserProfile.refresh();

      if (
        multistream.enabledDestinations.some(
          (destination) =>
            destination.type === "platform" &&
            destination.platform_type === "tiktok",
        )
      ) {
        console.log("opening tiktok monitor");
        api.golive.tiktok.links.ensure().then(({ monitor: monitorUrl }) => {
          console.log({ monitorUrl });
          if (monitorUrl) {
            // open the tiktok monitor
            openBrowserTo(monitorUrl);
          }
        });
      }

      chatDockUrl = auth.getMultistreamChatUrl(multistreamChatPlatforms);
    }

    if (chatDockUrl) {
      const chatDock = obs.getDockByTitle("Streamlabs: Chat");

      if (chatDock) {
        chatDock.executeJavascript(
          `window.location.href = ${JSON.stringify(chatDockUrl)}`,
        );
      }
    }

    obs.bringToFront();
    Bindings.qt.click_stream_button();
    $track("going_live_via_plugin_started", {
      type:
        multistream.enabledDestinations.length > 1 ? "multistream" : "basic",
      platforms: multistream.enabledDestinations
        .filter((d) => d.type === "platform")
        .map((d) => d.platform_type),
    });
    emit("cancel");
    busy.value = false;
  })();
};

const stepOrder = computed(() => [Step.Destinations, Step.Settings]);
const currentStepIdx = ref(0);
const currentStepId = computed(() => stepOrder.value[currentStepIdx.value]);
const currentStep = computed(() => steps[currentStepId.value]);

const hasSeenGoLiveFlow = ref<boolean>();

const onUpdateOpen = async (open: boolean) => {
  if (!open) {
    if (!hasSeenGoLiveFlow.value) {
      dialogs.showConfirmDialog({
        title: `Revert "Start Streaming" Action?`,
        description: `We see you're not wanting to go live via the Streamlabs Plugin "Go Live" flow. Would you like to revert the "Start Steaming" button back to it's previous action?\n\nNote: You can change this later by visiting Plugin Settings in the left nav.`,
        cancelBtnText: "Keep New Flow",
        confirmBtnText: "Revert To Default",
        onConfirm({ close }) {
          console.log("onConfirm");
          app.startStreamingFlowEnabled = false;
          close();
        },
      });

      await sleep(200);
    }

    emit("cancel");
  }
};

onMounted(() => {
  hasSeenGoLiveFlow.value = app.flags.hasSeenGoLiveFlow;
  app.setFlag("hasSeenGoLiveFlow", true);
});

watch(
  () => multistream.enabledDestinations,
  (is) => {
    form.setFieldValue("destinationCount", is.length, false);
  },
  { immediate: true },
);

watch(
  fieldsets,
  (is) => {
    if (!is) return;

    form.setValues(getInitialValues(is), false);
  },
  { deep: true },
);
</script>

<template>
  <ComboInput
    v-model="value"
    :options="comboOptions"
    placeholder="Select category..."
    @input="
      ($event) => {
        q = ($event.target as HTMLInputElement).value;
      }
    "
  >
    <template #item="{ option }">
      <div class="inline-flex items-center space-x-3">
        <NuxtImg
          :src="items.find((item) => item.id === option.value)?.box_art_url"
          class="h-10"
        />
        <span>{{ option.label }}</span>
      </div>
    </template>

    <template #empty>
      <UiCommandEmpty>
        <template v-if="willSearch">
          <div class="flex items-center justify-center">
            <Busy class="text-primary" />
          </div>
        </template>
        <template v-else-if="q.length">No categories found.</template>
        <template v-else>Search for a category.</template>
      </UiCommandEmpty>
    </template>
  </ComboInput>
</template>

<script setup lang="ts">
import { useVModel, watchDebounced } from "@vueuse/core";

export type Category = {
  box_art_url?: string;
  id: string;
  name: string;
};

type Value = Category["id"];

const props = withDefaults(
  defineProps<{
    id?: string;
    ariaDescribedby?: string;
    ariaInvalid?: boolean;
    modelValue?: Value;
    queryPath?: string;
    defaultCategories?: Category[];
    mapItem?: (item: any) => Category;
  }>(),
  {
    queryPath: "/api/v5/obs-plugin/go-live/twitch/info",
    defaultCategories: () => [
      {
        id: "509658",
        name: "Just Chatting",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/509658-285x380.jpg",
      },
      {
        id: "32982",
        name: "Grand Theft Auto V",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/32982_IGDB-285x380.jpg",
      },
      {
        id: "21779",
        name: "League of Legends",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/21779-285x380.jpg",
      },
      {
        id: "33214",
        name: "Fortnite",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/33214-285x380.jpg",
      },
      {
        id: "516575",
        name: "VALORANT",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/516575-285x380.jpg",
      },
      {
        id: "32399",
        name: "Counter-Strike",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/32399-285x380.jpg",
      },
      {
        id: "512710",
        name: "Call of Duty: Warzone",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/512710-285x380.jpg",
      },
      {
        id: "27471",
        name: "Minecraft",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/27471_IGDB-285x380.jpg",
      },
      {
        id: "29595",
        name: "Dota 2",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/29595-285x380.jpg",
      },
      {
        id: "512953",
        name: "ELDEN RING",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/512953_IGDB-285x380.jpg",
      },
      {
        id: "503932",
        name: "Last Epoch",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/503932_IGDB-285x380.jpg",
      },
      {
        id: "511224",
        name: "Apex Legends",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/511224-285x380.jpg",
      },
      {
        id: "143106037",
        name: "EA Sports FC 24",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/143106037_IGDB-285x380.jpg",
      },
      {
        id: "252812003",
        name: "Supermarket Simulator",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/252812003_IGDB-285x380.jpg",
      },
      {
        id: "18122",
        name: "World of Warcraft",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/18122-285x380.jpg",
      },
      {
        id: "766571430",
        name: "HELLDIVERS 2",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/766571430_IGDB-285x380.jpg",
      },
      {
        id: "498566",
        name: "Slots",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/498566-285x380.jpg",
      },
      {
        id: "263490",
        name: "Rust",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/263490_IGDB-285x380.jpg",
      },
      {
        id: "491487",
        name: "Dead by Daylight",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/491487-285x380.jpg",
      },
      {
        id: "518203",
        name: "Sports",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/518203-285x380.jpg",
      },
      {
        id: "29452",
        name: "Virtual Casino",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/29452_IGDB-285x380.jpg",
      },
      {
        id: "493887283",
        name: "Casino",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/493887283_IGDB-285x380.jpg",
      },
      {
        id: "491931",
        name: "Escape from Tarkov",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/491931_IGDB-285x380.jpg",
      },
      {
        id: "515025",
        name: "Overwatch 2",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/515025-285x380.jpg",
      },
      {
        id: "255298418",
        name: "FINAL FANTASY VII REBIRTH",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/255298418_IGDB-285x380.jpg",
      },
      {
        id: "26936",
        name: "Music",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/26936-285x380.jpg",
      },
      {
        id: "75467",
        name: "Euro Truck Simulator 2",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/75467-285x380.jpg",
      },
      {
        id: "13389",
        name: "Age of Empires II",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/13389-285x380.jpg",
      },
      {
        id: "745589378",
        name: "Balatro",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/745589378_IGDB-285x380.jpg",
      },
      {
        id: "460630",
        name: "Tom Clancy's Rainbow Six Siege",
        box_art_url:
          "https://static-cdn.jtvnw.net/ttv-boxart/460630_IGDB-285x380.jpg",
      },
    ],
  },
);

const emit = defineEmits<{ (e: "update:modelValue", value: Value): void }>();

const value = useVModel(props, "modelValue", emit);

const api = useApiStore();

const q = ref("");

const items = ref<Category[]>(props.defaultCategories);

const comboOptions = computed(() => {
  return items.value.map((item) => {
    const mappedItem = props.mapItem ? props.mapItem(item) : item;

    return {
      value: mappedItem.id,
      label: mappedItem.name,
    };
  });
});

const willSearch = ref(false);

watch(q, (q) => {
  willSearch.value = true;
});

watchDebounced(
  q,
  async (q) => {
    const response = await api.get<{
      categories?: Category[];
      platform: "twitch";
    }>(props.queryPath, {
      params: {
        category: q,
      },
    });

    items.value = response.data.categories || [];
    willSearch.value = false;
  },
  { debounce: 250, maxWait: 1000 },
);
</script>

<template>
  <UiAlertDialog>
    <UiAlertDialogContent>
      <UiAlertDialogHeader>
        <UiAlertDialogTitle class="flex items-center space-x-2">
          <IconUltra class="h-5 w-5" /><span>{{ title }}</span>
        </UiAlertDialogTitle>
        <UiAlertDialogDescription>
          {{ description }}
          <a
            :href="ultraLandingHref"
            target="_blank"
            class="underline-offset-4 hover:brightness-110"
            @click.stop.prevent="openBrowserTo(ultraLandingHref)"
            ><span class="underline">Click here</span> for more about
            <span class="text-ultra">Streamlabs Ultra</span></a
          >, or continue below.
        </UiAlertDialogDescription>
      </UiAlertDialogHeader>

      <div class="my-1" />

      <UiAlertDialogFooter>
        <UiAlertDialogCancel @click="$emit('cancel')">{{
          $t("Cancel")
        }}</UiAlertDialogCancel>
        <!-- <UiAlertDialogAction as-child> -->
        <Button variant="ultra" @click="openUltraCheckout">
          {{ $t("Join Streamlabs Ultra") }}
        </Button>
        <!-- </UiAlertDialogAction> -->
      </UiAlertDialogFooter>
    </UiAlertDialogContent>
  </UiAlertDialog>

  <!-- 
  <Dialog :title="title">
    <template #trigger><slot /></template>

    <p class="text-sm text-muted-foreground">
      {{ description }}
      <a
        :href="ultraLandingHref"
        target="_blank"
        class="underline underline-offset-4 hover:brightness-110"
        @click.stop.prevent="openBrowserTo(ultraLandingHref)"
        >Click here</a
      >
      for more about Streamlabs Ultra, or continue below.
    </p>

    <DialogFooter>
      <DialogClose variant="neutral" @click="$emit('cancel')">
        {{ $t("Cancel") }}
      </DialogClose>

      <DialogClose variant="primary" @click="openUltraCheckout">
        {{ $t("Join Streamlabs Ultra") }}
      </DialogClose>
    </DialogFooter>
  </Dialog>
  -->
</template>

<script setup lang="ts">
import { openBrowserTo } from "~/lib/bindings";
import { getUltraUrl } from "~/lib/ultra";
import { type DialogProps } from "~/stores/dialogs.store";

const { t: $t } = useI18n();

const emit = defineEmits(["cancel"]);

type Props = DialogProps["ULTRA_REQUIRED"];

const props = defineProps<Props>();

const ultraLandingHref = computed(() => {
  return getUltraUrl(props.params, false);
});

const openUltraCheckout = () => {
  openBrowserTo(getUltraUrl(props.params));
  emit("cancel");
};

const title = computed(() => {
  return $t("Ultra Is Required");
});

const description = computed(() => {
  switch (props.reason) {
    case "overlay_install":
      return `Installing a premium Overlay requires you to have an active Ultra
        membership.`;
    case "go_live_multiple_destinations":
      return `Streaming to multiple destinations requires an active Ultra membership.`;
    case "multistream_toggle":
      return `Streaming to multiple destinations requires an active Ultra membership.`;
  }

  return `The feature you're trying to use requires an active Ultra subscription.`;
});
</script>

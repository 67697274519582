<template>
  <DialogTrigger
    :as-child="asChild"
    :class="
      !asChild ? styles({ variant, size, condensed, class: props.class }) : null
    "
  >
    <slot />
  </DialogTrigger>
</template>

<script lang="ts" setup>
import { DialogTrigger } from "radix-vue";
import { type Props, styles } from "../Button/config";

const props = defineProps<Props & { asChild?: boolean }>();
</script>

<template>
  <div class="max-w-xl self-start pt-12">
    <video
      class="sm:h-80"
      :class="{
        '-mt-12 h-80 pb-4': false,
      }"
      src="https://cdn.streamlabs.com/obs-plugin/cloudbot-graphic.webm"
      autoplay
      loop
      muted
    />

    <Card class="mt-12">
      <div class="flex items-center justify-between space-x-2 p-4">
        <label for="enabled" class="flex flex-col space-y-1">
          <h3 class="text-lg font-medium">Enable Cloudbot</h3>
          <span class="font-normal leading-snug text-muted-foreground">
            something cool happens when you turn this on
          </span>
        </label>

        <UiSwitch
          id="enabled"
          variant="background"
          size="lg"
          v-model:checked="cloudbotEnabled"
        />
      </div>
    </Card>
  </div>
</template>

<script lang="ts" setup>
import { useVModel } from "@vueuse/core";

const props = defineProps<{
  cloudbotEnabled: boolean;
}>();

const emit = defineEmits(["update:cloudbotEnabled"]);

const cloudbotEnabled = useVModel(props, "cloudbotEnabled", emit);
</script>

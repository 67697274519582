<template>
  <div class="flex flex-col items-center justify-center">
    <div
      class="absolute inset-0 bg-cover bg-center opacity-80"
      :style="{
        'background-image':
          'radial-gradient(36.35% 59.59% at 71.46% 40.41%, rgba(0, 0, 0, 0.3) 41.46%, rgba(0, 0, 0, 0.5) 100%), url(https://cdn.streamlabs.com/obs-plugin/login-bg.jpg)',
      }"
    />

    <div class="z-10 flex flex-col items-center">
      <IconStreamlabs class="h-12 w-12" />

      <h2 class="mb-8 mt-4 font-heading text-[32px]">{{ app.name }}</h2>

      <Button
        size="lg"
        variant="primary"
        class="w-80"
        @click="() => auth.tryLogin()"
      >
        <div class="flex items-center justify-center space-x-2">
          <span>{{ $t("Log In & Get Started") }}</span>
        </div>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
const auth = useAuthStore();
const app = useAppStore();

onMounted(() => {
  // hack to allow feedback button to work
  document.body.style.pointerEvents = "";
});
</script>

<template>
  <Dialog :title="$t('Plugin Outdated')">
    <template #trigger><slot /></template>

    <p class="mb-4 text-sm text-muted-foreground">
      <template v-if="props.mode === 'restart_obs'">
        Please restart OBS to update the Streamlabs Plugin. If you continue to
        have issues, please contact support.
      </template>
      <template v-else>
        Your Streamlabs Plugin for OBS is outdated and was unable to
        automatically update. Please download the latest installer below.
      </template>
    </p>

    <DialogFooter>
      <Button variant="outline" @click="openStreamlabsSupport">
        {{ $t("Contact Support") }}
      </Button>

      <Button
        v-if="props.mode === 'restart_obs'"
        variant="primary"
        @click="Bindings.win.restartOBS"
      >
        {{ $t("Restart OBS") }}
      </Button>
      <Button v-else variant="primary" @click="downloadLatestUpdater">
        {{ $t("Download Update") }}
      </Button>
    </DialogFooter>
  </Dialog>
</template>

<script setup lang="ts">
import Bindings, { openBrowserTo } from "~/lib/bindings";
import { type DialogProps } from "~/stores/dialogs.store";

type Props = DialogProps["PLUGIN_OUTDATED"];
const props = defineProps<Props>();

const supportHref = "https://support.streamlabs.com/hc/en-us/requests/new";

const openStreamlabsSupport = () => {
  openBrowserTo(supportHref);
};

const downloadLatestUpdater = () => {
  openBrowserTo("https://streamlabs.com/obs-plugin/download");
};
</script>

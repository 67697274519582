<template>
  <UiAutoForm
    class="space-y-6"
    :schema="schema"
    :field-config="{
      name: {
        inputProps: {
          readonly: !!state,
        },
      },
      message: {
        component: 'textarea',
        inputProps: {
          type: 'email',
          readonly: !!state,
        },
      },
      email: {
        inputProps: {
          type: 'email',
          readonly: !!state,
        },
      },
    }"
    @submit="(values) => $emit('submit', values)"
  >
    <slot name="submit">
      <Button type="submit" class="w-full" :state="state"> Submit </Button>
    </slot>
  </UiAutoForm>
</template>

<script setup lang="ts">
import * as z from "zod";

defineEmits<{
  (e: "submit", values: Record<string, any>): void;
}>();

defineProps<{
  state?: "busy" | "success";
}>();

const schema = z.object({
  name: z
    .string({
      required_error: "Name is required.",
    })
    .min(2, {
      message: "Name must be at least 2 characters.",
    }),

  email: z
    .string({
      required_error: "Email is required.",
    })
    .email(),

  message: z.string(),
});
</script>

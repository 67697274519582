<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M8.053 2.414a1 1 0 0 0 0 1.414l2.121 2.122a1 1 0 0 0 1.414 0l2.122-2.122a1 1 0 0 0 0-1.414L11.588.293a1 1 0 0 0-1.414 0L8.053 2.414ZM1 0a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1ZM0 9a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V9Zm9-1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H9Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

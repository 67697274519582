<template>
  <div class="max-w-xl self-start pt-12">
    <video
      class="sm:h-80"
      :class="{
        '-mt-14 mb-14': true,
      }"
      src="https://cdn.streamlabs.com/obs-plugin/onboarding-tipping.webm"
      autoplay
      loop
      muted
    />

    <div class="mt-12 space-y-2">
      <h3 class="mb-4 text-lg font-medium">PayPal Email Address</h3>
      <div class="relative">
        <Input
          v-model="paypalEmail"
          type="email"
          name="email"
          autocomplete="email"
          autofocus
        />
      </div>
    </div>

    <div
      class="mb-6 mt-8 text-center text-sm font-medium leading-relaxed text-muted-foreground"
    >
      Do you have a PayPal Business account or want to accept credit cards?
      Visit the
      <a
        href="https://streamlabs.com/dashboard/tipping/methods"
        class="underline underline-offset-4 hover:brightness-110"
        @click.stop.prevent="
          (e) =>
            openBrowserTo('https://streamlabs.com/dashboard/tipping/methods')
        "
      >
        Streamlabs Dashboard
      </a>
      for more granular control.
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useVModel, useClipboard } from "@vueuse/core";
import { openBrowserTo } from "~/lib/bindings";
import { useToast } from "@/components/ui/toast/use-toast";

const auth = useAuthStore();
const { toast } = useToast();
const { copy: copyToClipboard, copied } = useClipboard();

watch(copied, (is) => {
  if (is) {
    toast({
      title: "URL Copied!",
      description: `Your Tip Page URL was copied to your clipboard.`,
    });
  }
});

const props = defineProps<{
  paypalEmail: string;
}>();

const emit = defineEmits(["update:paypalEmail"]);

const paypalEmail = useVModel(props, "paypalEmail", emit);
</script>

<template>
  <ul class="p-4">
    <Dialog
      v-if="auth.socketStatus === 'FAILED_TO_CONNECT'"
      :title="$t('Websocket Connection Error')"
      :description="
        $t(
          `We we're unable to connect to the Streamlabs Websocket. Please restart OBS and contact support if the issue persist.`,
        )
      "
    >
      <template #trigger>
        <li class="text-red-500">
          <DialogTrigger as-child>
            <NuxtLink>
              <Icon size="1.5em" name="lucide:unplug" />
              <span>{{ $t("Disconnected") }}</span>
            </NuxtLink>
          </DialogTrigger>
        </li>
      </template>
    </Dialog>

    <li v-if="auth.isAuthenticated">
      <MenusSimulateEvents
        @click="$track('left_nav_clicked', { nav_item_name: 'test_events' })"
      >
        <NuxtLink>
          <Icon size="1.5em" name="bxs:wrench" />
          <span>{{ $t("Test Events") }}</span>
        </NuxtLink>
      </MenusSimulateEvents>
    </li>
    <li v-if="!auth.isUltra">
      <NuxtLink
        :to="urlToUltra"
        @click.stop.prevent="
          () => {
            $track('left_nav_clicked', { nav_item_name: 'ultra' });
            openBrowserTo(urlToUltra);
          }
        "
      >
        <IconUltra class="h-6 w-6" />
        <span>{{ $t("pages.title.ultra") }}</span>
      </NuxtLink>
    </li>
    <li>
      <NuxtLink
        to="/settings"
        @click="$track('left_nav_clicked', { nav_item_name: 'settings' })"
      >
        <!-- <Icon size="1.5em" name="lucide:settings" /> -->
        <IconSettings class="h-6 w-6 p-0.5" />
        <span>{{ $t("pages.title.settings") }}</span>
      </NuxtLink>
    </li>

    <!--
      <li class="pb-2 pt-4">
        <Button
          v-if="!obs.isStreaming"
          class="w-full"
          variant="neutral"
          @click="onStartStream"
        >
          <IconRecord class="-ml-1.5 mr-1.5 h-5 w-5 opacity-70" />
          <span>{{ $t("Start Streaming") }}</span>
        </Button>
        <Button v-else class="w-full" variant="danger" @click="onStopStream">
          <IconStopRecording class="-ml-1.5 mr-1.5 h-5 w-5 opacity-70" />
          <span>{{ $t("Stop Streaming") }}</span>
        </Button>
      </li>
      -->
  </ul>
</template>
<script setup lang="ts">
import { openBrowserTo } from "~/lib/bindings";
import { getUltraUrl } from "~/lib/ultra";

const { track: $track } = useTrackingStore();
const auth = useAuthStore();

const urlToUltra = getUltraUrl({ refl: "obsplugin-left-nav" }, false);
</script>

<template>
  <Dialog :title="$t('Beta Testing')">
    <template #trigger><slot /></template>

    <p class="mb-4 text-sm text-muted-foreground">
      The Streamlabs Plugin for OBS is currently in
      <strong class="font-medium text-white">closed beta</strong>. If you would
      like to participate, please
      <a
        class="underline underline-offset-4 hover:brightness-110"
        :href="supportHref"
      >
        reach out to us</a
      >.
    </p>

    <DialogFooter>
      <Button variant="neutral" @click="auth.logout">
        {{ $t("Logout") }}
      </Button>
      <Button variant="primary" @click="openStreamlabsSupport">
        {{ $t("Contact Support") }}
      </Button>
    </DialogFooter>
  </Dialog>
</template>

<script setup lang="ts">
import { openBrowserTo } from "~/lib/bindings";

const auth = useAuthStore();

const supportHref = "https://support.streamlabs.com/hc/en-us/requests/new";

const openStreamlabsSupport = () => {
  openBrowserTo(supportHref);
};
</script>

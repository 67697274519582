<template>
  <div
    class="relative aspect-video"
    @dragover.prevent
    @drop.prevent="handleDrop"
    @click="triggerFileInput"
  >
    <div
      class="group relative h-full w-full cursor-pointer hover:brightness-110"
    >
      <input
        ref="fileInput"
        type="file"
        hidden
        :accept="props.allowTypes ? props.allowTypes.join(',') : undefined"
        @change="handleFileChange"
      />

      <Button
        variant="inline"
        size="sm"
        class="absolute inset-0 h-full w-full bg-secondary group-hover:z-10 group-hover:opacity-80"
      >
        <Icon name="lucide:upload" class="mr-2 text-white" size="1rem" />
        <span>Upload Thumbnail</span>
      </Button>
      <img
        v-if="imageUrl"
        :src="imageUrl"
        alt="Preview"
        class="absolute inset-0 h-full w-full overflow-hidden rounded-lg object-cover"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const imageUrl = ref<string | null>(null);
const file = ref<File | null>(null);
const fileInput = ref<HTMLInputElement | null>(null);

const props = withDefaults(
  defineProps<{
    modelValue?: File | string;
    maxSize?: number;
    allowTypes?: string[];
  }>(),
  {
    // modelValue:
    //   "https://i9.ytimg.com/vi/vMJONSm2UNA/mqdefault.jpg?sqp=CJiMg7MG&rs=AOn4CLAVxn1syr2GIufTZ9EyHZpYJoUKag",
    maxSize: 1024 * 1024 * 2,
    allowTypes: () => ["image/jpeg", "image/png", "image/gif"],
  },
);

watch(
  () => props.modelValue,
  (is) => {
    if (is instanceof File) {
      //   file.value = is;
    } else if (typeof is === "string") {
      imageUrl.value = is;
    }
  },
  { immediate: true },
);

const emit = defineEmits<{
  (e: "update:modelValue", value: File): void;
  (e: "input", event: InputEvent): void;
}>();

// const value = useVModel(props, "modelValue", emit);

const handleDrop = (event: DragEvent) => {
  const droppedFiles = event.dataTransfer?.files;
  if (droppedFiles && droppedFiles.length > 0) {
    handleFile(droppedFiles[0]);
  }
};

const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    handleFile(target.files[0]);
  }
};

const handleFile = (fileData: File) => {
  file.value = fileData;
  imageUrl.value = URL.createObjectURL(fileData);

  emit("update:modelValue", file.value);
};

const triggerFileInput = () => {
  fileInput.value?.click();
};
</script>

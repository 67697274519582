<template>
  <UiDialog @update:open="onUpdateOpen">
    <UiDialogContent :class="`max-w-${currentStep?.maxWidth}`">
      <UiDialogHeader>
        <UiDialogTitle>
          {{ currentStep?.title }}
        </UiDialogTitle>
        <UiDialogDescription>
          {{ currentStep?.description }}
        </UiDialogDescription>
      </UiDialogHeader>

      <div class="flex h-[614px] items-center justify-center">
        <DialogOnboardingStreamLabels
          v-if="currentStepId === Step.StreamLabels"
          v-model:import-stream-labels-enabled="importStreamLabelsEnabled"
        />

        <DialogOnboardingCloudbotStep
          v-if="currentStepId === Step.Cloudbot"
          v-model:cloudbot-enabled="cloudbotEnabled"
        />

        <DialogOnboardingTippingStep
          v-else-if="currentStepId === Step.Tipping"
          v-model:paypal-email="paypalEmail"
        />
        <DialogOnboardingOverlayStep
          v-else-if="currentStepId === Step.Overlay"
          v-model:selectedOverlayId="selectedOverlayId"
        />
        <DialogOnboardingUltraStep
          v-else-if="currentStepId === Step.Ultra"
          @complete="completeOnboarding"
        />
      </div>

      <!-- hide the footer if we're showing the login panel -->
      <DialogFooter v-if="auth.isAuthenticated">
        <!-- if there is a prev step, show Back button-->
        <Button
          v-if="currentStepIdx > 0"
          variant="neutral"
          class="sm:mr-auto"
          @click="currentStepIdx -= 1"
        >
          {{ $t("Back") }}
        </Button>

        <!-- if there is a next step, show Skip button-->
        <Button
          v-if="currentStepIdx < stepOrder.length - 1"
          variant="link"
          @click="doSkip"
        >
          {{ $t("Skip") }}
        </Button>

        <!-- if there is a next step, show Continue button-->
        <Button
          v-if="currentStepIdx < stepOrder.length - 1"
          :busy="busy"
          @click="doContinue"
        >
          {{ $t("Continue") }}
        </Button>

        <!-- don't show the finish button on the Ultra step -->
        <template v-else-if="currentStepId !== Step.Ultra">
          <Button variant="link" @click="completeOnboarding">
            {{ $t("Skip") }}
          </Button>
          <Button @click="completeOnboarding">
            {{ $t("Finish") }}
          </Button>
        </template>
      </DialogFooter>
    </UiDialogContent>
  </UiDialog>
</template>

<script setup lang="ts">
import { sleep } from "~/lib/util";

const { t: $t } = useI18n();
const { track: $track } = useTrackingStore();

enum Step {
  Overlay,
  Tipping,
  Cloudbot,
  Ultra,
  StreamLabels,
}

const emit = defineEmits(["cancel"]);

const busy = ref(false);

const steps: Record<
  Step,
  {
    title?: string;
    description?: string;
    maxWidth?:
      | "4xl"
      | "lg"
      | "xs"
      | "sm"
      | "md"
      | "xl"
      | "2xl"
      | "3xl"
      | "5xl"
      | "6xl"
      | "7xl"
      | undefined;
    doContinue?: () => void | Promise<void>;
    doSkip?: () => void | Promise<void>;
  }
> = {
  [Step.StreamLabels]: {
    title: $t("Import Your Stream Labels"),
    description: $t(
      `We've detected that you're using some Stream Labels. Let's import them!`,
    ),
    maxWidth: "3xl",
    doContinue: async () => {
      if (
        importStreamLabelsEnabled.value &&
        streamLabels.hasImportableStreamLabels
      ) {
        busy.value = true;
        await streamLabels.doImportStreamLabels();

        // the above doesn't seem to be async, so let's wait til there are
        // no labels to import
        while (streamLabels.hasImportableStreamLabels) {
          await sleep(100);
        }
        busy.value = false;
      }

      currentStepIdx.value += 1;
    },
  },
  [Step.Overlay]: {
    title: $t("Add an Overlay"),
    description: $t(
      `Professionally designed overlays to make your stream shine`,
    ),
    maxWidth: "3xl",
    doSkip() {
      selectedOverlayId.value = undefined;
      currentStepIdx.value += 1;
    },
  },

  [Step.Cloudbot]: {
    title: $t("Set Up Cloudbot"),
    description: $t(
      `Handle entertainment and moderation for your stream with our cloud-based chatbot`,
    ),
    maxWidth: "3xl",
  },

  // todo: skip this step if the user has tipping setup already
  [Step.Tipping]: {
    title: $t("Set Up Tipping"),
    description: $t(`Accept tips and engage with your audience`),
    maxWidth: "3xl",
  },

  // todo: skip this step if the user is already ultra
  [Step.Ultra]: {
    title: $t("Choose Your Plan"),
    description: $t(`Select a plan below to complete onboarding`),
    maxWidth: "4xl",
  },
};

const stepOrder = computed(() => {
  const steps = [Step.Overlay, Step.Tipping];

  if (
    streamLabels.hasHasImportableStreamLabels ||
    streamLabels.hasImportableStreamLabels
  ) {
    console.log(streamLabels.hasHasImportableStreamLabels);
    steps.unshift(Step.StreamLabels);
  }

  if (!auth.isUltra) {
    steps.push(Step.Ultra);
  }

  return steps;
});

const currentStepIdx = ref(0);

const currentStepId = computed(() => stepOrder.value[currentStepIdx.value]);
const currentStep = computed(() => steps[currentStepId.value]);

const doContinue = async () => {
  if (currentStep.value.doContinue) {
    await currentStep.value.doContinue();
  } else {
    currentStepIdx.value += 1;
  }
};

const doSkip = async () => {
  if (currentStep.value.doSkip) {
    await currentStep.value.doSkip();
  } else {
    currentStepIdx.value += 1;
  }
};

const selectedOverlayId = ref();
const paypalEmail = ref("");
const cloudbotEnabled = ref(true);
const importStreamLabelsEnabled = ref(true);

const auth = useAuthStore();
const app = useAppStore();
const streamLabels = useStreamLabelsStore();

const cancelOnboarding = () => {
  $track("onboarding_canceled");
  emit("cancel");
};

const completeOnboarding = () => {
  if (selectedOverlayId.value) {
    navigateTo(`/overlays/${selectedOverlayId.value}/install`);
  }

  app.onboardingComplete = true;
};

const onUpdateOpen = (open: boolean) => {
  if (!open) {
    cancelOnboarding();
  }
};

watch(
  () => app.onboardingComplete,
  (onboardingComplete) => {
    if (!onboardingComplete) {
      currentStepIdx.value = 0;
    }
  },
);
</script>

export type UltraCheckoutParams =
  | {
      refl: "obsplugin-theme-library";
      source: "library-overlays";
      ref_item: number;
      aff_id: number;
    }
  | {
      refl: "obsplugin-widget-library";
      source: "library-widget-themes";
      ref_item: number;
      aff_id: number;
    }
  | {
      refl:
        | "obsplugin-multistream"
        | "obsplugin-left-nav"
        | "obsplugin-onboarding";
    };

export const getUltraUrl = (
  params: UltraCheckoutParams,
  goToCheckout = true,
) => {
  const url = new URL("http://streamlabs.com/ultra");

  if (params) {
    for (const [p, v] of Object.entries(params)) {
      url.searchParams.set(p, v);
    }
  }

  if (goToCheckout) {
    url.searchParams.set("checkout", "true");
  }

  return url.toString();
};

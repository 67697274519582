<template>
  <div class="flex w-full flex-col items-center">
    <div class="grid w-full max-w-3xl grid-cols-3 grid-rows-2 gap-3">
      <OverlayGridItem
        v-for="(overlay, i) in overlays"
        :key="i"
        class="cursor-default select-none"
        :class="{
          'outline outline-2 outline-primary':
            overlay && overlay.id === selectedOverlayId,
          'hoverpop cursor-pointer':
            overlay && overlay.id !== selectedOverlayId,
        }"
        :overlay="overlay || undefined"
        :hide-additional-images="true"
        :hide-designer="true"
        @click="overlay ? (selectedOverlayId = overlay.id) : undefined"
      />
    </div>
    <div
      class="mb-6 mt-8 text-center text-sm font-medium leading-relaxed text-muted-foreground"
    >
      {{ $t("Or browse from thousands more overlays after onboarding") }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { useVModel } from "@vueuse/core";
import streamlabs from "~/lib/streamlabs-api";

const browse = streamlabs.fetchOverlaysBrowse();

const freeOverlays: Overlay[] = [
  {
    id: 2624,
    user_id: 7684923,
    name: "Streamlabs Neon",
    description: "",
    designer_name: "Streamlabs",
    designer_email: "",
    overlay_type: "static",
    color: "teal,blue",
    game_category: "all,dance,variety,irl,fps,action,collab-cam,dual-output",
    game: "All",
    labels: [],
    included_screens: ["live", "start", "brb"],
    language: "",
    preview_images: [
      "https://cdn.streamlabs.com/marketplace/overlays/7684923/eeeb9e1/preview-images/eeeb9e1-1.png",
      "https://cdn.streamlabs.com/marketplace/overlays/7684923/eeeb9e1/preview-images/eeeb9e1-2.png",
    ],
    custom_images: {
      live: "https://cdn.streamlabs.com/marketplace/overlays/7684923/eeeb9e1/custom-images/eeeb9e1-live.png",
      start:
        "https://cdn.streamlabs.com/marketplace/overlays/7684923/eeeb9e1/custom-images/eeeb9e1-start.png",
      brb: "https://cdn.streamlabs.com/marketplace/overlays/7684923/fce1c30/custom-images/fce1c30-brb.png",
    },
    approval_status: "approved",
    related_widget_theme: null,
    installs: 292535,
    installs_2day: 8,
    featured: 0,
    custom_order: 1000,
    product_url: "",
    product_discount_percent: 0,
    created_at: "2021-09-21T00:35:16.000000Z",
    updated_at: "2023-11-28T00:58:53.000000Z",
    prime: 0,
    designer: {
      id: 1,
      sl_user_id: 7684923,
      name: "Streamlabs",
      avatar: "",
      design_master: true,
      works_for: null,
      description: "",
      website: "https://streamlabs.com/library",
      email: null,
      created_at: null,
      updated_at: null,
    },
  },
  {
    id: 2639,
    user_id: 7684923,
    name: "Purple Starburst",
    description: "",
    designer_name: "Streamlabs",
    designer_email: "",
    overlay_type: "static",
    color: "purple,white",
    game_category: "all,variety,other,fps,dance,collab-cam,dual-output",
    game: "All",
    labels: [],
    included_screens: ["live", "start", "brb"],
    language: "",
    preview_images: [
      "https://cdn.streamlabs.com/marketplace/overlays/7684923/a1a4ab0/preview-images/a1a4ab0-1.png",
    ],
    custom_images: {
      live: "https://cdn.streamlabs.com/marketplace/overlays/7684923/a1a4ab0/custom-images/a1a4ab0-live.png",
      start:
        "https://cdn.streamlabs.com/marketplace/overlays/7684923/a1a4ab0/custom-images/a1a4ab0-start.png",
      brb: "https://cdn.streamlabs.com/marketplace/overlays/7684923/d0056ca/custom-images/d0056ca-brb.png",
    },
    approval_status: "approved",
    related_widget_theme: null,
    installs: 27058,
    installs_2day: 0,
    featured: 0,
    custom_order: 1000,
    product_url: "",
    product_discount_percent: 0,
    created_at: "2021-09-29T16:51:59.000000Z",
    updated_at: "2023-11-28T00:00:01.000000Z",
    prime: 0,
    designer: {
      id: 1,
      sl_user_id: 7684923,
      name: "Streamlabs",
      avatar: "",
      design_master: true,
      works_for: null,
      description: "",
      website: "https://streamlabs.com/library",
      email: null,
      created_at: null,
      updated_at: null,
    },
  },
  {
    id: 2656,
    user_id: 7684923,
    name: "Red Glitch",
    description: "",
    designer_name: "Streamlabs",
    designer_email: "",
    overlay_type: "static",
    color: "red,white",
    game_category: "all,fps,action,mmo,variety,collab-cam,dual-output",
    game: "All",
    labels: [],
    included_screens: ["live", "start", "brb"],
    language: "",
    preview_images: [
      "https://cdn.streamlabs.com/marketplace/overlays/7684923/59acc9a/preview-images/59acc9a-1.png",
      "https://cdn.streamlabs.com/marketplace/overlays/7684923/59acc9a/preview-images/59acc9a-2.png",
      "https://cdn.streamlabs.com/marketplace/overlays/7684923/59acc9a/preview-images/59acc9a-3.png",
    ],
    custom_images: {
      live: "https://cdn.streamlabs.com/marketplace/overlays/7684923/59acc9a/custom-images/59acc9a-live.png",
      start:
        "https://cdn.streamlabs.com/marketplace/overlays/7684923/59acc9a/custom-images/59acc9a-start.png",
      brb: "https://cdn.streamlabs.com/marketplace/overlays/7684923/f470325/custom-images/f470325-brb.png",
    },
    approval_status: "approved",
    related_widget_theme: null,
    installs: 10491,
    installs_2day: 2,
    featured: 0,
    custom_order: 1000,
    product_url: "",
    product_discount_percent: 0,
    created_at: "2021-10-06T18:27:55.000000Z",
    updated_at: "2023-11-28T01:13:06.000000Z",
    prime: 0,
    designer: {
      id: 1,
      sl_user_id: 7684923,
      name: "Streamlabs",
      avatar: "",
      design_master: true,
      works_for: null,
      description: "",
      website: "https://streamlabs.com/library",
      email: null,
      created_at: null,
      updated_at: null,
    },
  },
];

const overlays = computed(() => {
  if (browse.data.value?.just_added) {
    const overlays = useShuffle(browse.data.value.just_added).slice(0, 9);

    overlays[1] = freeOverlays[0];
    overlays[3] = freeOverlays[1];
    overlays[5] = freeOverlays[2];

    return overlays;
  }

  return [null, null, null, null, null, null, null, null, null];
});

const props = defineProps<{
  selectedOverlayId: number | undefined;
}>();

const emit = defineEmits(["update:paypalEmail"]);

const selectedOverlayId = useVModel(props, "selectedOverlayId", emit);
</script>
